@import "styles/variables";

.mainContainer {
  padding-top: 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.container {
  height: 88.7%;
  display: flex;
  gap: 24px;
  background-color: #1A1A1A;
  overflow: hidden;
}

.borderRadius {
  border-radius: 29.19px;
}

.photoContainer {
  width: 320px;
  height: 320px;
  margin: 0 8px;
  border-radius: 24px;
}

.footerWrapper {
  height: 10.37%;
}

.footerBtnWrapper {
  height: 100%;
  display: flex;
  gap: 5%;
}

.defaultBtnWrapper {
  border: 1px solid rgba(246, 246, 246, 0.3);
  border-radius: 24px;
  padding: 12px;
  width: 100%;
}

.logoWrapper {
  display: none;
}

.defaultBtn {
  border-radius: 16px;
  height: 100%;
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: 0 16px;
  font-size: 16px;
  font-family: $MatterRegular;
}

.bgGray {
  background-color: rgba(131, 129, 126, 1);
}

.bgGold {
  background-color: rgba(213, 172, 122, 1);
}

.gradientBg {
  opacity: 0.65;
  background: #1A1A1A;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.footerLeftWrapper {
  padding: 24px 40px;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .mainContainer {
    padding-top: 14px;
  }

  .footerBtnWrapper {
    gap: 14px;
  }

  .logoWrapper {
    display: flex;
  }

  .container {
    gap: 24px;
    height: 88.07%;
  }

  .photoContainer {
    width: 400px;
    height: 400px;
  }

  .defaultBtn {
    font-size: 20px;
  }

  .btn {
    width: 42.9%;
  }
}

@media screen and (min-width: 1024px)  {
  .footerBtnWrapper {
    gap: 24px;
  }

  .defaultBtn {
    font-size: 25px;
  }

  .mainContainer {
    padding-top: 24px;
  }

  .borderRadius {
    border-radius: 32px;
  }

  .logoWrapper {
    display: flex;
  }

  .container {
    gap: 30px;
    height: 87.7%;
  }

  .photoContainer {
    width: 400px;
    height: 400px;
  }

  .btn {
    width: 42.9%;
  }
}