@import "styles/variables";

.leftContainer {
  width: 54.63%;
  background:  #1A1A1A;
  border-radius: 29.19px;
  height: 100%;
  padding: 5.29% 15.87% 5.29% 5.29%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.textContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.rightContainer {
  width: 43.4%;
  background:  #F6F6F6;
  height: 100%;
  border-radius: 29.19px;
}

.container {
  height: calc(95dvh - 106px);
  display: flex;
  justify-content: space-between;
}

.header {
  color: #E6E3DE;
  font-family: $MatterRegular;
  font-size: 31px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  letter-spacing: -1.22px;
}

.subscription {
  color: #83817E;
  font-family: $MatterRegular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  letter-spacing: -0.5px;
}

.customBtn {
  padding: 4px 12px;
  border-radius: 16px;
  font-size: 10px;
  letter-spacing: -0.26px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnsContainer {
  gap: 12px;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .textContainer {
    gap: 18px;
  }

  .header {
    font-size: 41px;
    line-height: 110%;
    letter-spacing: -1px;
  }

  .subscription {
    font-size: 20px;
    line-height: 125%;
    letter-spacing: -0.4px;
  }

  .container {
    height: calc(95dvh - 106px);
  }

  .customBtn {
    padding: 16px 32px;
    border-radius: 16px;
    font-size: 20px;
    letter-spacing: -0.2px;
  }

  .btnsContainer {
    gap: 24px;
  }
}

@media screen and (min-width: 1024px)  {
  .container {
    height: calc(92dvh - 114px);
  }

  .textContainer {
    gap: 24px;
  }

  .header {
    font-size: 61px;
    line-height: 110%;
    letter-spacing: -1.22px;
  }

  .subscription {
    font-size: 25px;
    line-height: 125%;
    letter-spacing: -0.5px;
  }

  .customBtn {
    padding: 24px 48px;
    border-radius: 24px;
    font-size: 20px;
    letter-spacing: -0.2px;
  }

  .btnsContainer {
    gap: 24px;
  }
}