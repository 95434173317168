@import "src/styles/variables";

.container {
  height: 87.7%;
  display: flex;
  gap: 36px;
  background-color: #1A1A1A;
}

.borderRadius {
  border-radius: 29.19px;
}

.header {
  font-size: 39px;
  font-family: $MatterRegular;
}

.customBtn {
  padding: 24px 48px;
  border-radius: 16px;
  font-size: 20px;
  letter-spacing: -2%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnsContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.footerWrapper {
  height: 10.37%;
}

.footerBtnWrapper {
  height: 100%;
  display: flex;
  gap: 14px;
}

.defaultBtnWrapper {
  border: 1px solid rgba(246, 246, 246, 0.3);
  border-radius: 24px;
  padding: 12px;
}

.footerLeftWrapper {
  padding: 24px 40px;
}

.btn {

}

.logoWrapper {
  display: none;
}

.defaultBtn {
  border-radius: 16px;
  height: 100%;
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: 0 16px;
  font-size: 16px;
  font-family: $MatterRegular;
}

.grayBtn {
  background-color: rgba(131, 129, 126, 1);
}

.btn {
  width: 100%;
}



@media screen and (min-width: 768px) and (max-width: 1023px) {
  .container {
    gap: 24px;
  }

  .customBtn {
    padding: 16px 32px;
    border-radius: 16px;
    font-size: 20px;
    letter-spacing: -0.2px;
  }

  .btnsContainer {
    gap: 24px;
    flex-direction: row;
  }

  .btn {
    width: 31.9%;
  }

  .logoWrapper {
    display: flex;
  }
}


@media screen and (min-width: 1024px)  {
  .borderRadius {
    border-radius: 32px;
  }

  .container {
    gap: 30px;
  }

  .customBtn {
    padding: 24px 48px;
    border-radius: 24px;
    font-size: 20px;
    letter-spacing: -0.2px;
  }

  .btnsContainer {
    gap: 24px;
    flex-direction: row;
  }

  .logoWrapper {
    display: flex;
  }

  .btn {
    width: 31.9%;
  }
}