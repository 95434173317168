.bgContainer {
  background-image: url("~assets/images/intro-bg.png");
  background-repeat: no-repeat;
  background-position-x: 85%;
  background-size: cover;
}

@media screen and (min-width: 1024px)  {
  .bgContainer {
    background-position-x: 0;
    background-position-y: 35%;
    background-size: cover;
  }
}