@media screen and (min-width: 1024px)  {
  .block {
    width: 24%;
    transition: width 0.2s ease-in-out;
  }

  .block.active {
    width: 47.6%;
  }
}

.bgExample2 {
  background-repeat: no-repeat;
  background-position-x: 50%;
  //background-image: url("~assets/images/example2.png");
  background-size: cover;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;
  animation-duration: 0.7s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;

  & img {
    width: auto; /* Adjust width as needed */
    height: 100%; /* Maintain aspect ratio */
    object-fit: cover;
  }

  &.animated {
    animation-name: slide-in;
  }
}

.bgExample3 {
  background-repeat: no-repeat;
  background-position-x: 50%;
  //background-image: url("~assets/images/example3.png");
  background-size: cover;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;

  animation-duration: 0.7s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;

  & img {
    width: auto; /* Adjust width as needed */
    height: 100%; /* Maintain aspect ratio */
    object-fit: cover;
  }

  &.animated {
    animation-name: slide-in;
  }
}

.bgExample1 {
  background-color: #1A1A1A;
  background-repeat: no-repeat;
  background-position-x: 50%;
  background-size: cover;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;
  //background-image: url("~assets/images/example1.png");

  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;

  & img {
    width: auto; /* Adjust width as needed */
    height: 100%; /* Maintain aspect ratio */
    object-fit: cover;
  }

  &.animated {
    animation-name: slide-in;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slide-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
