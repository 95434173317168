@import "styles/variables";

.footer-container {
  border-radius: 29.191px;
  text-align: center;
  font-family: $MatterRegular;
  height: 106px;
}

.footer-text {
  font-size: 14px;
  font-family: $MatterRegular;
  font-style: normal;
  letter-spacing: -0.28px;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
 .footer-container {
   border-radius: 32px;
   height: 106px;
 }

  .footer-text {
    font-size: 14px;
  }
}

@media screen and (min-width: 1024px)  {
  .footer-container {
    border-radius: 32px;
    height: 114px;
  }

  .footer-text {
    font-size: 16px;
  }
}