@import "variables";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  background-color: transparent;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

*::-webkit-scrollbar {
  display: none;
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
  -webkit-background-clip: text;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #23232329;
  outline: none;
  color: rgb(240, 240, 240);
  -webkit-text-fill-color: rgb(240, 240, 240);
  background: white;
  font: inherit; /* Inherit font properties from parent element */
}

input {
  border: none;
  outline: none;
  background: none;
  font: inherit; /* Inherit font properties from parent element */
  padding: 0; /* Adjust padding if needed */
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  width: 100%;
}

input[type="range"]::-webkit-slider-runnable-track {
  background: #D5AC7A;
  height: 3px;
  border-radius: 8px;
  width: 100%;
}

input[type="range"]::-moz-range-track {
  background: #D5AC7A;
  height: 3px;
  border-radius: 8px;
  width: 100%;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  margin-top: -7px;
  background-color: #D5AC7A;
  height: 18px;
  width: 18px;
  border-radius: 28px;
}



input[type="range"]::-moz-range-thumb {
  border: none; /*Removes extra border that FF applies*/
  margin-top: -7px;
  background-color: #D5AC7A;
  height: 18px;
  width: 18px;
  border-radius: 8px;
}

@media screen and (min-width: 768px) {
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    margin-top: -3px;
    background-color: #D5AC7A;
    height: 9px;
    width: 9px;
    border-radius: 28px;
  }



  input[type="range"]::-moz-range-thumb {
    border: none; /*Removes extra border that FF applies*/
    margin-top: -3px;
    background-color: #D5AC7A;
    height: 9px;
    width: 9px;
    border-radius: 8px;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
  -webkit-background-clip: text;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #23232329;
}

html, body {
  overscroll-behavior: none;
  background-color: $black;
  width: 100%;
  height: 100%;
}

h4 {
  color: #1A1A1A;
  font-size: 31px;
  letter-spacing: -0.62px;
  font-weight: normal;
  font-family: $MatterRegular;
}

.f-matter {
  font-family: $MatterRegular;
}

.f-size39 {
  font-size: 39px;
}

.flex {
  display: flex;
  justify-content: center;
}

.a-items-c {
  align-items: center;
}

.t-align-c {
  text-align: center;
}

.t-align-l {
  text-align: left;
}

.a-items-l {
  align-items: flex-start;
}

.mt80 {
  margin-top: 80px;
}

.mt40 {
  margin-top: 40px;
}

.justify-content-start {
  justify-content: flex-start;
}

.space-between {
  justify-content: space-between;
}

.flex-column {
  flex-direction: column;
}

.padding-h40 {
  padding-left: 40px;
  padding-right: 40px;
}

.position-absolute {
  position: absolute;
}

.position-relative {
  position: relative;
}

.overflow-hidden {
  overflow: hidden;
}

.full-width {
  width: 100%;
}

.default-border {
  border: 1px solid $border;
}

.full-height {
  height: 100%;
}

.text-gold {
  color: $spGold;
}

.text-white {
  color: $white;
}

.text-gray {
  color: #E6E3DE;
}

.text-gray2 {
  color: #83817E;
}

.f-size24 {
  font-size: 24px;
}

.mt12 {
  margin-top: 12px;
}

.mb12 {
  margin-bottom: 12px;
}

.mb20 {
  margin-bottom: 20px;
}

.btn {
  display: inline-block;
  width: 100%;
  border-radius: 29.191px;
  border: none;
  color: $black;
  background-color: $spGold;
  text-align: center;
  font-family: $MatterRegular;
  font-style: normal;
  line-height: normal;
}

.intro-btn {
  margin-top: 10px;
  height: 18vh;
  font-size: 32px;
  letter-spacing: -0.64px;
  font-weight: 400;
}

.intro-footer {
  margin-top: 10px;
}

.intro-bg {
  overflow: hidden;
}

.gold-bg-btn {
  background-color: #D5AC7A;
  color: #1A1A1A;
}

.gray-btn {
  background-color: #C4C4BC;
  color: #1A1A1A;
}

.dark-bg-btn {
  border: 2px solid #E6E3DE;
  color: #E6E3DE;
}

.text-color-light {
  color: #E6E3DE;
}

.gap8 {
  gap: 8px;
}

.gap24 {
  gap: 24px;
}

.input-error {
  border: 2px solid #e12a2a;
}

.error-text {
  color: #e12a2a;
  font-family: $MatterRegular;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

h5 {
  font-family: $MatterRegular;
  font-size: 25px;
  color: #C4C4BC;
}

@media screen and (max-width: 767px)  {
  .app-padding {
    padding: 12px;
  }

  .logo-width {
    width: 260px;
  };

  .intro-bg {
    border-radius: 29.191px;
    height: calc(77dvh - 108px);
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .app-padding {
    padding: 14px;
  }

  .intro-footer {
    margin-top: 14px;
  }

  .logo-width {
    width: 260px;
  }

  .intro-bg {
    border-radius: 32px;
    height: calc(69dvh - 108px);
  }

  .intro-btn {
    margin-top: 14px;
    height: 25dvh;
    border-radius: 32px;
    font-size: 39px;
  }

  h4 {
    font-size: 39px;
    line-height: 120%;
    letter-spacing: -0.78px;
  }
}

@media screen and (min-width: 1024px)  {
  .app-padding {
    padding: 24px;
  }

  h5 {
    font-size: 31px;
  }

  .intro-footer {
    margin-top: 24px;
  }

  .logo-width {
    width: 460px;
  }

  .intro-bg {
    border-radius: 32px;
    height: calc(65dvh - 114px);
  }

  .intro-btn {
    margin-top: 24px;
    height: 25dvh;
    border-radius: 32px;
    font-size: 49px;
    letter-spacing: -0.98px;
  }

  h4 {
    font-size: 39px;
    line-height: 120%;
    letter-spacing: -0.78px;
  }
}

.cursor-pointer {
  cursor: pointer;
}
