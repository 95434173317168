@import "styles/variables";

.dark-color {
  background: #1A1A1A;
  color: #FFF;
  font-weight: 400;
  font-family: $MatterRegular;
  font-style: normal;
  line-height: 150%;
}

.light-color {
  background-color: transparent;
  color: #111;
  border: 2px solid #1A1A1A;
  font-family: $MatterRegular;
  font-weight: 400;
  font-style: normal;
  line-height: 150%;
}

.modal-btns-container {
  gap: 24px;
}

.modal-text {
  color: #83817E;
  font-size: 20px;
  font-weight: 400;
  font-family: $MatterRegular;
  line-height: 150%;
  letter-spacing: -0.4px;
}

@media screen and (max-width: 767px)  {
  .modal-content-container {
    padding: 83px 0 53px;
  }

  .custom-modal-btn {
    padding: 16px 32px;
    border-radius: 16px;
    font-size: 13px;
    letter-spacing: -0.26px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-content-gap {
    gap: 32px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .modal-content-container {
    padding: 64px 40px;
  }

  .modal-content-gap {
    gap: 40px;
  }

  .custom-modal-btn {
    padding: 24px 48px;
    border-radius: 16px;
    font-size: 20px;
    letter-spacing: -0.2px;
  }

  .modal-text {
    font-size: 25px;
    line-height: 125%;
    letter-spacing: -0.5px;
  }
}

@media screen and (min-width: 1024px)  {
  .modal-content-container {
    padding: 84px 80px;
  }

  .modal-content-gap {
    gap: 40px;
  }

  .custom-modal-btn {
    padding: 24px 48px;
    border-radius: 24px;
    font-size: 20px;
    letter-spacing: -0.2px;
  }

  .modal-text {
    font-size: 25px;
    line-height: 125%;
    letter-spacing: -0.5px;
  }
}