.flicking-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #111;
  margin: 0 4px
}

.flicking-pagination-bullet-active {
  width: 48px;
  height: 12px;
  border-radius: 40px;
  background-color: #D5AC7A;
}

.flicking-viewport {
  overflow: initial;
}

.flicking-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  bottom: -50px;
  height: 20px;
}

.flicking-camera {
  display: flex;
}