@import "styles/variables";

.mainContainer {
  padding-top: 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.container {
  height: 74.7%;
  display: flex;
  gap: 2%;
  background-color: #1A1A1A;
}

.borderRadius {
  border-radius: 29.19px;
}

.photoContainer {
  width: 320px;
  height: 320px;
  border-radius: 24px;
}

.actionsContainer {
  width: 320px;
}

.header {
  color: #D5AC7A;
  font-family: $MatterRegular;
  font-size: 25px;
}

.stepsContainer {
  display: flex;
  gap: 8px;
}

.footerWrapper {
  height: 10.37%;
}

.footerBtnWrapper {
  height: 100%;
  display: flex;
  gap: 5%;
}

.defaultBtnWrapper {
  border: 1px solid rgba(246, 246, 246, 0.3);
  border-radius: 24px;
  padding: 12px;
  width: 100%;
}

.logoWrapper {
  display: none;
}

.defaultBtn {
  border-radius: 16px;
  height: 100%;
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: 0 16px;
  font-size: 16px;
  font-family: $MatterRegular;
}

.bgGray {
  background-color: rgba(131, 129, 126, 1);
}

.bgGold {
  background-color: rgba(213, 172, 122, 1);
}

.bgActive {
  background: #C4C4BC;
  color: #1A1A1A;
}

.bgDefault {
  background-color: rgba(17, 17, 17, 1);
  color: #C4C4BC;
}

.mobileBtnWrapper {
  height: 10.37%;
  width: 100%;
  gap: 5%;
}

.getPhotoBtnWrapper {
  height: 100%;
  width: 100%;
  border-radius: 24px;
  border: 1px solid rgba(246, 246, 246, 0.3);
  padding: 12px;
}

.PoseOutline{
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;

  img {
    width: 100%;
    height: 100%;
  }
}

.getPhotoBtn {
  border: 1px solid rgba(230, 227, 222, 1);
  border-radius: 20px;
  height: 100%;
  color: rgba(230, 227, 222, 1);
  font-size: 20px;
  font-family: $MatterRegular;
  display: flex;
  justify-content: center;
  align-items: center;
}

.goldText {
  font-size: 25px;
  font-family: $MatterRegular;
}

.smallBtn {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 24px;
  font-size: 16px;
  font-family: $MatterRegular;
}

.textBtn {
  color:  #C4C4BC;
  font-family: $MatterRegular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.32px;
}

.photoContainerAbsolute {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.75) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .mainContainer {
    padding-top: 14px;
  }

  .footerBtnWrapper {
    gap: 14px;
  }

  .logoWrapper {
    display: flex;
  }

  .container {
    gap: 24px;
    height: 88.07%;
  }

  .photoContainer {
    width: 400px;
    height: 400px;
  }

  .getPhotoBtnWrapper {
    display: none;
  }

  .mobileBtnWrapper {
    display: none;
  }

  .goldText {
    font-size: 31px;
  }

  .actionsContainer {
    width: 400px;
  }

  .defaultBtn {
    font-size: 20px;
  }

  .getPhotoBtn {
    font-size: 16px;
  }
}

@media screen and (min-width: 1024px)  {
  .footerBtnWrapper {
    gap: 24px;
  }

  .defaultBtn {
    font-size: 25px;
  }

  .actionsContainer {
    width: 400px;
  }

  .getPhotoBtn {
    font-size: 20px;
  }

  .goldText {
    font-size: 31px;
  }

  .mainContainer {
    padding-top: 24px;
  }

  .borderRadius {
    border-radius: 32px;
  }

  .logoWrapper {
    display: flex;
  }

  .container {
    gap: 30px;
    height: 87.7%;
  }

  .photoContainer {
    width: 400px;
    height: 400px;
  }

  .header {
    font-size: 31px;
  }

  .getPhotoBtnWrapper {
    display: none;
  }

  .mobileBtnWrapper {
    display: none;
  }
}
