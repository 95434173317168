.container {
  height: 100%;
  display: flex;
  gap: 24px;
  background-image: url("~assets/images/intro-bg.png");
  background-repeat: no-repeat;
  background-position-x: 85%;
  background-size: cover;
}

.borderRadius {
  border-radius: 29.19px;
}

.logoWrapper {
  position: absolute;
  top: 22px;
}

.textWrapper {
  position: absolute;
  bottom: 32px;
}

@media screen and (min-width: 1024px)  {
  .borderRadius {
    border-radius: 32px;
  }

  .container {
    background-position-x: right;
    background-position-y: 35%;
    background-size: cover;
  }

  .logoWrapper {
    position: absolute;
    left: 34px;
    top: 22px;
  }

  .textWrapper {
    position: absolute;
    bottom: 39px;
    right: 35px;
  }
}