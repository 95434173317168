@import "styles/variables";

.container {
  height: 87.7%;
  display: flex;
  justify-content: space-between;
}

.displayBlock {
  display: none;
}

.fullBlock {
  width: 100%;
  height: 100%;
}

.bgExample1 {
  background-image: url("~assets/images/example1.png");
  background-color: #1A1A1A;
  background-repeat: no-repeat;
  background-position-x: 50%;
  background-size: cover;
  position: relative;

  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;

  &.animated {
    animation-name: fade-in;
  }

  &.animated {
    animation-name: scale-in;
  }

  &.animated {
    animation-name: slide-in;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes scale-in {
  from {
    background-size: 0;
  }

  to {
    background-size: 100%;
  }
}

@keyframes slide-in {
  from {
    background-position-x: 100%;
  }

  to {
    background-position-x: 50%;
  }
}

.bgExample2 {
  background-repeat: no-repeat;
  background-position-x: 50%;
  background-image: url("~assets/images/example2.png");
  background-size: cover;
  position: relative;
}

.bgExample3 {
  background-repeat: no-repeat;
  background-position-x: 50%;
  background-image: url("~assets/images/example3.png");
  background-size: cover;
  position: relative;
}

.defaultBg {
  background: #E6E3DE;
}

.defaultRightBg {
  background: #C4C4BC;
}

.borderRadius {
  border-radius: 29.19px;
}

.smallBlock {
  height: 100%;
  padding: 40px 20px;
  flex-direction: column;
  justify-content: flex-end;
}

.rightContainer {
  display: none;
  width: 30%;
  background: #C4C4BC;
  height: 100%;

  padding: 40px 20px;
  flex-direction: column;
  justify-content: flex-end;
}

.headerText {
  display: flex;
  justify-content: center;
  color: #FFF;
  position: absolute;
  top: 32px;
  left: 50%;
  transform: translateX(-50%);
  font-family: $MatterRegular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: 0.96px;
  text-transform: uppercase;
}

.blurContainer {
  overflow: hidden;
  position: absolute;
  border-radius: 24px;
  background: rgba(194, 194, 194, 0.05);
  backdrop-filter: blur(10.997182846069336px);
  bottom: 16px;
  left: 16px;
  right: 16px;
  padding: 24px;

  .blurTitle {
    color: #FFF;
    font-family: $MatterRegular;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.62px;
    text-align: center;
  }
}

.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;

  p {
    text-align: left;
  }

  .header {
    color: #83817E;
    font-family: $MatterRegular;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 0.96px;
    text-transform: uppercase;
  }

  .title {
    height: 74px;
    color: #1A1A1A;
    font-family: $MatterRegular;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.62px;
  }
}

.footerWrapper {
  height: 10.37%;
}

.footerBtnWrapper {
  height: 100%;
  display: flex;
  gap: 14px;
}

.defaultBtnWrapper {
  border: 1px solid rgba(246, 246, 246, 0.3);
  border-radius: 24px;
  padding: 12px;
  width: 100%;
}

.logoWrapper {
  display: none;
}

.defaultBtn {
  border-radius: 16px;
  height: 100%;
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: 0 16px;
  font-size: 16px;
  font-family: $MatterRegular;
}

.bgGray {
  background-color: rgba(131, 129, 126, 1);
}

.bgGold {
  background-color: rgba(213, 172, 122, 1);
}


@media screen and (min-width: 768px) and (max-width: 1023px) {

  .fullBlock {
    background-position-y: 30%;
  }

  .textContainer {
    gap: 20px;
  }

  .headerText {
    font-size: 16px;
  }

  .blurContainer {
    border-radius: 32px;
    bottom: 24px;
    left: 24px;
    right: 24px;
    padding: 30px;

    .blurTitle {
      font-size: 32px;
    }
  }
}

@media screen and (min-width: 1024px)  {
  .borderRadius {
    border-radius: 32px;
  }

  .logoWrapper {
    display: flex;
  }

  .displayBlock {
    display: flex;
  }

  .activeBlock {
    width: 47.6%;
    height: 100%;
    background-position-x: 50%;
    background-position-y: 50%;
  }

  .rightContainer {
    display: flex;
    width: 24%;
  }

  .textContainer {
    gap: 24px;
  }

  .headerText {
    font-size: 16px;
    position: absolute;
    margin-top: 0;
    top: 40px;
    left: 40px;
  }

  .blurContainer {
    border-radius: 45px;
    bottom: 40px;
    left: 40px;
    right: 40px;
    padding: 43px;

    .blurTitle {
      font-size: 32px;
    }
  }

  .footerBtnWrapper {
    gap: 24px;
  }
}
