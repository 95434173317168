@import "src/styles/variables";

.header {
  color: #E6E3DE;
  font-family: $MatterRegular;
  font-size: 49px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  letter-spacing: -1.22px;
}

.subscription {
  color: #83817E;
  font-family: $MatterRegular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  letter-spacing: -0.5px;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .header {
    font-size: 49px;
    line-height: 110%;
    letter-spacing: -1px;
  }

  .subscription {
    font-size: 20px;
    line-height: 125%;
    letter-spacing: -0.4px;
  }
}

@media screen and (min-width: 1024px) {
  .header {
    font-size: 61px;
    line-height: 110%;
    letter-spacing: -1.22px;
  }

  .subscription {
    font-size: 25px;
    line-height: 125%;
    letter-spacing: -0.5px;
  }
}