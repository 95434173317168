@import "styles/variables";

.qr-footer-container {
  border-radius: 29.191px;
  text-align: center;
  font-family: $MatterRegular;
  height: 106px;
  padding: 0 5%;

  img {
    width: 3.9%;
  }
}

.qr-footer-text {
  font-size: 10px;
  font-family: $MatterRegular;
  font-style: normal;
  letter-spacing: -0.28px;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
 .qr-footer-container {
   border-radius: 32px;
   height: 106px;
   padding: 0 40px;
 }

  .qr-footer-text {
    font-size: 14px;
  }
}

@media screen and (min-width: 1024px)  {
  .qr-footer-container {
    border-radius: 32px;
    height: 114px;
    padding: 0 40px;
  }

  .qr-footer-text {
    font-size: 16px;
  }
}