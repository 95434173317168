@import 'styles/variables';

.label {
  color: rgba(240, 240, 240, 1);
  font-size: 20px;
  margin-bottom: 10px;
  font-family: $MatterRegular;
}

.input {
  height: 44px;
  width: 100%;
  background-color: rgba(46, 46, 46, 1);
  border-radius: 16px;
  padding-left: 16px;
  color: rgba(240, 240, 240, 1);
  font-family: $MatterRegular;
  font-size: 20px;

  ::placeholder {
    color: rgba(131, 129, 126, 1);
    font-size: 20px;
  }
}

.searchIcon {
  position: absolute;
  left: 16px;
  top: 12px;
}

.disabledInput {
 background-color: rgb(30, 29, 29);
  cursor: pointer;
}

.required::after {
  content: "*";
  font-size: 20px;
  color: rgba(240, 240, 240, 1); /* Adjust color as needed */
  font-weight: bold; /* Make it stand out */
  margin-left: 5px; /* Add spacing between text and star */
}