@import "styles/variables";

.mainContainer {
  padding-top: 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.container {
  height: 74.7%;
  display: flex;
  gap: 36px;
  background-color: #1A1A1A;
}

.borderRadius {
  border-radius: 29.19px;
}

.cameraContainer {
  width: 320px;
  height: 320px;
  border-radius: 24px;
}

.photoContainer {
  width: 320px;
  height: 331px;
  border-radius: 24px;
}

.header {
  color: #D5AC7A;
  font-family: $MatterRegular;
  font-size: 25px;
}

.stepsContainer {
  display: flex;
  gap: 8px;
}

.footerWrapper {
  height: 10.37%;
}

.footerBtnWrapper {
  height: 100%;
  display: flex;
  gap: 5%;
}

.defaultBtnWrapper {
  border: 1px solid rgba(246, 246, 246, 0.3);
  border-radius: 24px;
  padding: 12px;
  width: 100%;
}

.logoWrapper {
  display: none;
}

.defaultBtn {
  border-radius: 16px;
  height: 100%;
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: 0 16px;
}

.bgGray {
  background-color: rgba(131, 129, 126, 1);
}

.bgGold {
  background-color: rgba(213, 172, 122, 1);
}

.frameContainer {
  position: absolute;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
  }
}

.mobileBtnWrapper {
  height: 10.37%;
  width: 100%;
  gap: 5%;
}

.getPhotoBtnWrapper {
  height: 100%;
  width: 100%;
  border-radius: 24px;
  border: 1px solid rgba(246, 246, 246, 0.3);
  padding: 12px;
}

.getPhotoBtn {
  border: 1px solid rgba(230, 227, 222, 1);
  border-radius: 20px;
  height: 100%;
  color: rgba(230, 227, 222, 1);
  font-size: 16px;
  font-family: $MatterRegular;
  display: flex;
  justify-content: center;
  align-items: center;
}

.getPhotoSmallBtnWrapper {
  display: none;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .mainContainer {
    padding-top: 14px;
  }

  .footerBtnWrapper {
    gap: 14px;
  }

  .logoWrapper {
    display: flex;
  }

  .container {
    gap: 24px;
    height: 88.07%;
  }

  .cameraContainer {
    width: 400px;
    height: 400px;
  }

  .photoContainer {
    width: 400px;
    height: 400px;
  }

  .getPhotoBtnWrapper {
    display: none;
  }

  .getPhotoSmallBtnWrapper {
    display: flex;
    height: 10.37%;
    border-radius: 24px;
    padding: 12px 48px;
    border: 1px solid rgba(230, 227, 222, 1);
    color: rgba(230, 227, 222, 1);
    font-size: 20px;
    font-family: $MatterRegular;
    justify-content: center;
    align-items: center;
  }

  .mobileBtnWrapper {
    display: none;
  }
}

@media screen and (min-width: 1024px)  {
  .footerBtnWrapper {
    gap: 24px;
  }

  .mainContainer {
    padding-top: 24px;
  }

  .borderRadius {
    border-radius: 32px;
  }

  .logoWrapper {
    display: flex;
  }

  .container {
    gap: 30px;
    height: 87.7%;
  }

  .cameraContainer {
    width: 400px;
    height: 400px;
  }

  .photoContainer {
    width: 400px;
    height: 400px;
  }

  .header {
    font-size: 31px;
  }

  .getPhotoBtnWrapper {
    display: none;
  }

  .getPhotoSmallBtnWrapper {
    display: flex;
    height: 10.37%;
    border-radius: 24px;
    padding: 12px 48px;
    border: 1px solid rgba(230, 227, 222, 1);
    color: rgba(230, 227, 222, 1);
    font-size: 20px;
    font-family: $MatterRegular;
    justify-content: center;
    align-items: center;
  }

  .mobileBtnWrapper {
    display: none;
  }
}
