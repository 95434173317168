@import "styles/variables";

.leftContainer {
  width: 54.63%;
  background:  #1A1A1A;
  border-radius: 29.19px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mainContainer {
  display: flex;
  gap: 12px;
  flex-direction: column;
  justify-content: space-between;
}

.textContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.rightContainer {
  background:  #F6F6F6;
  height: 100%;
  border-radius: 29.19px;
}

.mobileImagesContainer {
  background:  #F6F6F6;
  border-radius: 24px;
  padding: 4% 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 330px;
}

.desktopContainer {
  display: none;
}


.gridTemplate {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-gap: 6px;
  height: 100%;
}

.header {
  color: #E6E3DE;
  font-family: $MatterRegular;
  font-size: 31px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  letter-spacing: -1.22px;
}

.subscription {
  color: #83817E;
  font-family: $MatterRegular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  letter-spacing: -0.5px;
}

.customBtn {
  padding: 4px 12px;
  border-radius: 16px;
  font-size: 10px;
  letter-spacing: -0.26px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobileCustomBtn {
  max-width: 330px;
  width: 100%;
  font-size: 16px;
  padding: 16px 32px;
  border-radius: 24px;
}

.btnsContainer {
  gap: 12px;
}

.footerBtnWrapper {
  height: 100%;
  display: flex;
  gap: 14px;
}

.defaultBtnWrapper {
  border: 1px solid rgba(246, 246, 246, 0.3);
  border-radius: 24px;
  padding: 12px;
}

.footerLeftWrapper {
  padding: 24px 40px;
}

.logoWrapper {
  display: none;
}

.defaultBtn {
  border-radius: 16px;
  height: 100%;
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: 0 16px;
  font-size: 16px;
  font-family: $MatterRegular;
}

.goldBtn {
  background-color: #D5AC7A;
}

.btn {
  width: 100%;
}

.activeImgContainer {
  background: #D5AC7A;
}

.isDefault {
  background: rgba(26, 26, 26, 0.78);
}

.isActive {
  background: #D5AC7A;
}

.activeImageContainer {
  border: 3px solid #D5AC7A;
}

.mobileContainer {
  width: 100%;
  height: 87.7%;
  background:  #1A1A1A;
  border-radius: 32px;
  padding: 30px 24px 24px 24px;
}

.footerWrapper {
  height: 10.37%;
}

.imageContainer {
  width: 80px;
  overflow: auto;
  height: 100%;
  border-radius: 16px;

  img {
    width: 100%;
    border-radius: 12px;
    object-fit: cover;
    object-position: top;
  }
}

.defaultDownloadBtn {
  width: 21px;
  height: 21px;
  border-radius: 32px;
  bottom: 6px;
  right: 6px;
}

.textContainer {
  gap: 8px;
  max-width: 400px;
}

@media screen and (min-width: 768px) and (max-width: 1134px) {

  .mobileContainer {
    height: 87.7%;
    gap: 24px;
    display: flex;
  }

  .subscription {
    font-size: 16px;
  }

  .logoWrapper {
    display: flex;
  }

  .mobileImagesContainer {
    background:  #F6F6F6;
    border-radius: 24px;
    padding: 4% 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 330px;
  }

  .leftContainer {
    padding: 5.29% 0 5.29% 2.29%;
  }

  .textContainer {
    gap: 18px;
  }

  .header {
    font-size: 36px;
    letter-spacing: -1px;
  }

  .desktopContainer {
    display: none;
  }

  .customBtn {
    padding: 16px 32px;
    border-radius: 16px;
    font-size: 20px;
    letter-spacing: -0.2px;
  }

  .mobileCustomBtn {
    max-width: 330px;
    width: 100%;
    font-size: 16px;
    padding: 16px 32px;
    border-radius: 24px;
  }

  .btnsContainer {
    gap: 24px;
  }

  .textContainer {
    justify-content: space-between;
    width: 410px;
  }

  .rightContainer {
    min-width: 445px;
    width: 445px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .imageContainer {
    width: 80px;
    overflow: auto;
    height: 100%;
    border-radius: 12px;

    img {
      width: 100%;
      border-radius: 12px;
      object-fit: cover;
      object-position: top;
    }
  }

  .defaultDownloadBtn {
    width: 20px;
    height: 20px;
    border-radius: 32px;
    bottom: 6px;
    right: 6px;
  }

  .btn {
    width: 42.9%;
  }
}


@media screen and (min-width: 1135px)  {
  .mobileContainer {
    display: none;
  }

  .logoWrapper {
    display: flex;
  }

  .desktopContainer {
    height: 87.7%;
    gap: 24px;
    display: flex;
  }

  .leftContainer {
    padding: 5.29% 0 5.29% 5.29%;
  }

  .textContainer {
    gap: 24px;
    width: 388px;
  }

  .header {
    font-size: 61px;
    line-height: 110%;
    letter-spacing: -1.22px;
  }

  .subscription {
    font-size: 25px;
    line-height: 125%;
    letter-spacing: -0.5px;
  }

  .customBtn {
    padding: 24px 48px;
    border-radius: 24px;
    font-size: 20px;
    letter-spacing: -0.2px;
  }

  .btnsContainer {
    gap: 24px;
  }

  .leftContainer {
    width: 100%;
  }

  .rightContainer {
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .gridTemplate {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-gap: 24px;
    height: 100%;
  }

  .imageContainer {
    width: 160px;
    overflow: auto;
    height: 100%;
    border-radius: 16px;

    img {
      width: 100%;
      border-radius: 16px;
      object-fit: cover;
      object-position: top;
    }
  }

  .defaultDownloadBtn {
    width: 40px;
    height: 40px;
    border-radius: 32px;
    bottom: 6px;
    right: 6px;
  }

  .btn {
    width: 42.9%;
  }

  .defaultBtn {
    font-size: 25px;
  }
}
